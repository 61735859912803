/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500&family=Mulish&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

::-ms-reveal{
    display: none;
}







.text-invalid{
    @apply pt-1 flex text-[#E2574C] text-[10px];
}
html,body{
    @apply h-full
}
.button-short{
    @apply py-[14px] px-[40px] focus:outline-none text-center text-[20px] text-white  leading-[24px] rounded-lg font-medium;
}
.primary-button{
    @apply cursor-pointer px-5 text-center text-[16px] overflow-hidden leading-6 font-semibold tracking-wider disabled:to-blueBADCEE hover:bg-blueBADCEE hover:text-textblue h-[50px] rounded-[16px] text-white bg-blue hover:border-textblue ;
}
.primary-button-disable{
    @apply cursor-pointer px-5 text-center text-[16px] leading-6 font-semibold tracking-wider bg-bluebuttondisable h-[50px] rounded-[16px] text-white opacity-50;
}
.button-long{
    @apply py-[8px] px-[92px] bg-buttonYellow focus:bg-buttonYellowFocused focus:outline-none disabled:bg-buttonYellowDisabled  text-[20px] text-white font-medium leading-[24px] rounded-lg;
}
.active{
    @apply bg-greenactive text-textgre rounded-full py-1 px-[20px]  
}
.deactive{
    @apply bg-reddeactive text-red  rounded-full py-1 px-[16px]    
}
.inactive{
    @apply bg-inactive text-textgray rounded-full py-1 px-[20px] 
}

.noncheck{
    @apply bg-blue text-white px-6 py-2 rounded-lg
}
.check{
    @apply bg-primaryblue text-blue px-6 py-2 rounded-lg
}
.toastSucceed{
    @apply w-[520px] h-[80px] text-textgre bg-greenactive pt-5 pl-5 pr-2
}
.toastFail{
    @apply w-[520px] h-[80px] text-textred bg-reddeactive pt-5 pl-5 pr-2
}
